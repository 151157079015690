<template lang="pug">
  div
    section.site-section(style="padding-bottom: 2rem")
      div.container(style="max-width: 100% !important")
        div.row
          div.col-12.text-center
            h3.section-title-sub.text-primary Weather
            h2.section-title.mb-3 今日天气
        div.row
          iframe(src="weather/weather.html" frameborder="0" scrolling="no")


</template>

<script>
export default {
  name: "Weather"
}
</script>

<style scoped>

iframe {
  border-width: 0;
  width: 100%;
  height: 30rem;
  display: block;
}

</style>