<template lang="pug">
  div
    section.site-section(style="padding-bottom: 2rem")
      div.container(style="max-width: 100% !important")
        div.row
          div.col-12.text-center
            h3.section-title-sub.text-primary Photo wall
            h2.section-title.mb-3 照片墙
        div.row
          iframe(src="reflective-photo-wall/index.html" frameborder="0" scrolling="no")


</template>

<script>
export default {
  name: "PhotoWall"
}
</script>

<style scoped>


iframe {
  border-width: 0;
  width: 100%;
  height: 30rem;
  display: block;
}

</style>