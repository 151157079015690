<template lang="pug">
  section#friends.site-section.block__45439
    div.container
      div.row.mb-5
        div.col-12.text-center
          h3.section-title-sub.text-primary Friends
          h2.section-title.mb-3 小伙伴们
      div.row
        Friend(
          v-for="f in friends"
          :key="f.link"
          :title="f.title"
          :sub-title="f.subTitle"
          :link="f.link"
          :img="f.img"
          )

</template>

<script>
import Friend from "@/components/Friend";
export default {
  name: "Friends",
  components: {Friend},
  data(){
    return {
      friends:[
        {
          title:"minemine",
          subTitle:"Write something for my love.",
          link:"https://minemine.cc/",
          img: "https://i.loli.net/2019/04/28/5cc57985cee35.jpg"
        },
        {
          title:"For(;;);",
          subTitle:"做平凡的事，成为一个平凡的人 。<br>" +
              "不戚戚于贫贱，不汲汲于富贵。<br>" +
              "心有猛虎，细嗅蔷薇。<br>" +
              "面向阳光，做一个开朗自信的人。",
          link:"https://blog.d0zingcat.xyz/",
          img: "https://i.loli.net/2019/11/23/Gv856EdWjheCZQI.jpg"
        },
        {
          title:"ColdStone",
          subTitle:"EVERYBODY DIES, BUT NOT EVERYBODY LIVES.",
          link:"https://coldstone.fun/",
          img: "https://i.loli.net/2020/07/16/rTKtQ43pGXwzWmc.jpg"
        },
        {
          title:"黎明余光",
          subTitle:"For the next infinity.",
          link:"https://idawnlight.com",
          img: "https://image.gslb.dawnlab.me/d2b97da956fe27167b882cc79d13f533.jpg"
        },
        {
          title:"Ralf Ren",
          subTitle:"Super gattovelocità!",
          link:"https://ralf.ren/",
          img: "https://i.loli.net/2021/02/20/cpe2fIjtoJx4wGg.jpg"
        },
        {
          title:"Zero",
          subTitle:"夏天结束了!",
          link:"https://mikuac.com/",
          img: "https://mikuac.com/images/zero.jpg"
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>