<template lang="pug">
  div.col-md-6.col-lg-6.mb-4
    div.block__86547.d-block.d-xl-flex.align-items-stretch.friend-shadow
      figure.img(:style="{backgroundImage: `url(${img})`}" @click="openLink(link)")
      div.h-100
        h3.mb-4
          a(:href='link' target='_blank') {{ title }}
        div.block__27192.d-flex.pt-1.border-top
          span.sub-title(v-html="subTitle")
</template>

<script>
export default {
  name: "Friend",
  props:{
    title:String,
    subTitle:String,
    link:String,
    img: String
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.img {
  cursor: pointer;
}

.sub-title {
  font-size: xx-small;
  max-height: 1rem;
}

.friend-shadow {
  border-radius: 7px;
  -webkit-box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.1);
}

</style>