<template lang="pug">
  div#app
    div.site-wrap
      About
      Friends
      Weather
</template>

<script>
import '@/assets/css/style.css';
import '@/assets/css/custom-bs.css';
import About from "@/components/About";
import Friends from "@/components/Friends";
import Weather from "@/components/Weather";
import PhotoWall from "@/components/PhotoWall";

export default {
  name: 'App',
  components: {
    PhotoWall,
    About,
    Friends,
    Weather
  }
}
</script>

<style>
</style>
